
window.onload = function () {
    var totop = document.getElementById('totop');


    window.addEventListener('scroll', function(){ 
        if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
            totop.style.opacity = '1';
        } else {
            totop.style.opacity = '0';
        }
    });

    totop.addEventListener('click', function() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
}