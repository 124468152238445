export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = 0;
    document.cookie = cname + "=" + cvalue + "; " + expires  + "; path=/";
   // location.reload();
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


export function switchUserStatus() {
    let userstatus = getCookie('user_status');
    
    switch (userstatus) {
        case '1':
            userstatus = '2';
            break;
        case '2':
            userstatus = '1';
            break;
        default:
            userstatus = '0';
    }
    console.log(userstatus);

    setCookie('user_status', userstatus, 30);
    window.location = window.location.href.split("?")[0];
    //location.reload();
}
		

export function deleteCookie(cname) {
  document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
			
				
