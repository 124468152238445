//import {BaseComponent} from "@lvm/lvm_framework";

/**
 * Akkordeon Element
 */
export default class Accordion {

  /**
   * @param app Kann ignoriert werden
   * @param e Das Element an dem die Komponente initialisiert wurde (z.B. <div data-lvm-component="TeamSelect" data-team="[...]">)
   */
  constructor(e) {

    this.Btns = e.getElementsByClassName("accordion__button");
    this.notCollapsed = e.querySelectorAll("[panel-collapsed=false]");

    this.resize();

    for (let i = 0; i < this.Btns.length; i++) {
      this.Btns[i].addEventListener("click", event => {this.switch(this.Btns[i])});
    }

    window.addEventListener("resize", event => {this.resize();});
  }


  switch(element) {

    var accordionItem = element.parentNode;
    var accordionPanel = accordionItem.querySelector('.accordion__panel');
    var allSiblings = Array.from(accordionItem.parentNode.children).filter(sibling => sibling.textContent !== accordionItem.textContent); 

    var isCollapsed = accordionPanel.getAttribute('panel-collapsed') === 'true';

    if(isCollapsed) {
      this.expand(accordionPanel);
      //collapse all Siblings
      allSiblings.forEach(elem => {
        this.collapse(elem.querySelector('.accordion__panel'));
      });


    } else {
      this.collapse(accordionPanel);
    }
  }


  collapse(element) {
  
    element.style.maxHeight = null;
  
    // mark the section as "currently collapsed"
    element.setAttribute('panel-collapsed', 'true');
    element.classList.add('collapsed');
    
    element.parentElement.classList.add('collapsed');
  }

  expand(element) {

    element.style.maxHeight = element.scrollHeight + "px";
  
    // mark the section as "currently not collapsed"
    element.setAttribute('panel-collapsed', 'false');
    element.classList.remove('collapsed');
    
    element.parentElement.classList.remove('collapsed');
  }

  resize() {
  
    this.notCollapsed.forEach((element) => {
      if (element !== null) {
        element.style.maxHeight = element.scrollHeight + "px";
      }
    });
    
  }

}
