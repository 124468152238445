import { getCookie,setCookie, deleteCookie } from "./cookie.js";



export default function init_nav() {
    var collapsebtn = document.querySelector('.nav__collapse_btn');
    var body = document.querySelector('body');
    var navbar = document.querySelector('.nav__sidebar');
    var collapsebtnsub = document.querySelectorAll('.nav__btncollapse--sub');
    var submenubtn = document.querySelectorAll('.nav__chevron--sub');
    var subsubmenubtn = document.querySelectorAll('.nav__chevron--subsub');
    var overlaybtn = document.querySelector('.nav__btncollapse--sub');

 
    collapsebtn.addEventListener('click', function() {
        body.classList.toggle('collapsed__nav');
        navbar.classList.toggle('nav--collapsed');

        var cookienow = getCookie('r2s_sidebar_status');
        if (cookienow == 0) {
            setCookie('r2s_sidebar_status', 1, 30);
        } else {
            setCookie('r2s_sidebar_status', 0, 30);
        }
    });


    submenubtn.forEach(function (btn) {
        btn.addEventListener('click', function() {

            btn.parentElement.parentElement.querySelector('.nav__submenu').classList.toggle('nav__submenu--collapsed');
            btn.classList.toggle('chevron-up');
            btn.classList.toggle('chevron-down');

            let all_child = btn.parentElement.parentElement.parentElement.children;
            console.log(all_child);

            for(child of all_child) {
                if(child != btn.parentElement.parentElement && child.querySelector('.nav__submenu'))  {
                    child.querySelector('.nav__submenu').classList.add('nav__submenu--collapsed');
                    child.querySelector('.nav__chevron').classList.remove('chevron-up');
                    child.querySelector('.nav__chevron').classList.add('chevron-down');
                }
            }
        })
    });

    subsubmenubtn.forEach(function (subbtn) {
        subbtn.addEventListener('click', function() {

            subbtn.parentElement.nextElementSibling.classList.toggle('nav__submenu--collapsed');
            subbtn.classList.toggle('chevron-up');
            subbtn.classList.toggle('chevron-down');

        });
    });

    if(overlaybtn) {
        overlaybtn.addEventListener('click', function() {
            overlaybtn.parentElement.classList.toggle('nav__overlay--collapsed');
            overlaybtn.querySelector('span').classList.toggle('double-chevron-right');
            overlaybtn.querySelector('span').classList.toggle('double-chevron-left');
        });
    }
}
    