export function toggleSearch() {
    document.querySelector(".search__content").classList.toggle("show");
}

export function showSearch() {
    document.querySelector(".search__content").classList.add("show");
}

export function hideSearch() {
    document.querySelector(".search__content").classList.remove("show");
}

export function checkClick(event) {
    if(document.querySelector(".search")) {
        if (!event.target.closest(".search")) {
            hideSearch();
        } 
    }
   
}

export function filterFunction() {
    var input, filter, ul, li, a, i;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    div = document.querySelector(".search__content");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
        } else {
        a[i].style.display = "none";
        }
    }
}