import "./scss/main.scss";

// import navbar from "@lvm/skoda-library/assets/js/navbar.js";
// window.navbar = navbar;


import totop from "@lvm/skoda-library/assets/js/totop.js";
window.totop = totop;
//import dialog from "@lvm/skoda-library/assets/js/components/dialog.js";
//window.dialog = dialog;
import Accordion from "@lvm/skoda-library/assets/js/components/accordion.js";
window.Accordion = Accordion;


// import { burgerbtn } from "@lvm/skoda-library/assets/js/components/nav.js";
// window.burgerbtn = burgerbtn;
import init_nav from "./js/nav.js";
window.onload = function() {
    init_nav();
}

import sidebar from "./js/sidebar.js";
sidebar();

import { switchUserStatus,setCookie, deleteCookie } from "./js/cookie.js";
window.switchUserStatus = switchUserStatus;
window.setCookie = setCookie;
window.deleteCookie = deleteCookie;


import {showSearch, hideSearch, checkClick, filterFunction } from "./js/search.js";
window.showSearch = showSearch;
window.hideSearch = hideSearch;
window.filterFunction = filterFunction;
window.onclick = checkClick;


import './js/countdown.js';
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.countdown')) {
    const countdownTime = parseInt(document.querySelector('.countdown').dataset.countdown);
    var cd = new Countdown({
      cont: document.querySelector('.countdown'),
      date: countdownTime,
      outputTranslation: {
          year: 'Jahre',
          week: 'Wochen',
          day: 'Tage',
          hour: 'Stunden',
          minute: 'Minuten',
          second: 'Sekunden',
      },
      endCallback: null,
      outputFormat: 'day|hour|minute|second',
    });
    cd.start();
  }
 
});

import Glide from '@glidejs/glide'

if (document.querySelector('.glide') != null) {
  new Glide('.glide', {
    type: "slider",
    peek: { before: 24, after: 48 },
    autoplay: 4000
  }).mount()
}
