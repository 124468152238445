export default function sidebar() {

    const sidebar = document.querySelector('.sidebar');
    const overlay = document.querySelector('.overlay');

    if( document.querySelector('.sidebar__button') !== null) {
        document.querySelector('.sidebar__button').addEventListener('click', function() {
            overlay.classList.toggle('show');
            sidebar.classList.toggle('collapsed');
            sidebar.querySelector('.sidebar__button .sa-i').classList.toggle('double-chevron-left');
            sidebar.querySelector('.sidebar__button .sa-i').classList.toggle('double-chevron-right');
        })
    }
  

}